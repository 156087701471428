import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HomeMenu from '../views/Home_menu.vue'
// import store from '../store/index'
import autenticacao from '../store/modules/autenticacao'

import { verificaToken, autenticaUsuarioToken } from '@/common/services/usuarioService'
import { setUserObj, adicionaAuthorization } from '@/common/localStorage/localStorage'
import { getUserObj } from '../common/localStorage/localStorage'

Vue.use(VueRouter)

const somenteAutenticado = async (to, from, next) => {
  try {
    let sessao = getUserObj();
    if (sessao) {
      let ret = await autenticaUsuarioToken(sessao.usuario.idUser, sessao.token)
      setUserObj(ret.data)
      adicionaAuthorization(sessao.token)      
      return next()
    } else {
      return next({ name: 'rota-home' })  
    }
  } catch (error) {
    return next({ name: 'rota-home' })
  }
}

const routes = [
  {
    path: '/',
    name: 'rota-home',
    //component: Home,
    component: () => import('../views/login.vue'), 
    meta: {
      // layout: 'LayoutNavBar',
      layout: null,
      titulo: 'Sem autenticação'
    },
  },
  {
    path: '/home',
    name: 'rota-home-auth',
    component: HomeMenu,
    beforeEnter: somenteAutenticado,
    meta: {
      layout: 'LayoutNavBar',
      titulo: 'Principal'
    },
  },
  // {
  //   path: '/huawei',
  //   name: 'rota-huawei',
  //   component: () => import('../views/huawei.vue'),
  //   meta: {
  //     layout: null,
  //     titulo: 'Huawei - Principal'
  //   },
  // },
  {
    path: '/config/empresas',
    name: 'rota-config-empresas',
    component: () => import('../views/config/Empresa.vue'),
    beforeEnter: somenteAutenticado,
    meta: {
      layout: 'LayoutNavBar',
      titulo: 'Cadastro de Empresas'
    },
  },
  {
    path: '/config/usuarios',
    name: 'rota-config-usuarios',
    component: () => import('../views/config/Usuario.vue'),
    beforeEnter: somenteAutenticado,
    meta: {
      layout: 'LayoutNavBar',
      titulo: 'Cadastro de Usuarios'
    }
  },
  {
    path: '/consulta/origem/notas',
    name: 'rota-consulta-notas',
    component: () => import('../views/consultas/ConsultaNotas.vue'),
    beforeEnter: somenteAutenticado,
    meta: {
      layout: 'LayoutNavBar',
      titulo: 'Consulta de notas'
    },
  },
  {
    path: '/inventario',
    name: 'rota-inventario',
    component: () => import('../views/inventario/Inventario.vue'),
    beforeEnter: somenteAutenticado,
    meta: {
      layout: 'LayoutNavBar',
      titulo: 'Inventário'
    },
  },
  {
    path: '/depara',
    name: 'rota-depara',
    component: () => import('../views/de-para/Index.vue'),
    beforeEnter: somenteAutenticado,
    meta: {
      layout: 'LayoutNavBar',
      titulo: 'De-para'
    },
    children: [
      {
        path:'/depara/mensagem',
        name: 'rota-depara-mensagem',
        component: ()=> import('../views/de-para/modulos/Mensagem.vue'),
        beforeEnter: somenteAutenticado,
        meta: {
          layout: 'LayoutNavBar',
          titulo: 'De-para mensagens'
        }
      },
      {
        path:'/depara/contacontabil',
        name: 'rota-depara-conta-contabil',
        component: ()=> import('../views/de-para/modulos/ContaContabil.vue'),
        beforeEnter: somenteAutenticado,
        meta: {
          layout: 'LayoutNavBar',
          titulo: 'De-para conta contábil'
        }
      },
      {
        path:'/depara/itemsped',
        name: 'rota-depara-item-sped',
        component: ()=> import('../views/de-para/modulos/ItemSped.vue'),
        beforeEnter: somenteAutenticado,
        meta: {
          layout: 'LayoutNavBar',
          titulo: 'De-para item - sped'
        }
      },
      // {
      //   path:'/depara/produto',
      //   name: 'rota-depara-produto',
      //   component: ()=> import('../views/de-para/modulos/Produto.vue'),
      //   beforeEnter: somenteAutenticado,
      //   meta: {
      //     layout: 'LayoutNavBar',
      //     titulo: 'De-para produto'
      //   }
      // },
      {
        path:'/depara/ncm',
        name: 'rota-depara-ncm',
        component: ()=> import('../views/de-para/modulos/Ncm.vue'),
        beforeEnter: somenteAutenticado,
        meta: {
          layout: 'LayoutNavBar',
          titulo: 'De-para NCM'
        }
      },
      {
        path:'/depara/participantes',
        name: 'rota-depara-participantes',
        component: ()=> import('../views/de-para/modulos/Participantes.vue'),
        beforeEnter: somenteAutenticado,
        meta: {
          layout: 'LayoutNavBar',
          titulo: 'De-para Participantes'
        }
      },
      {
        path:'/depara/seriefiscal',
        name: 'rota-depara-seriefiscal',
        component: ()=> import('../views/de-para/modulos/SerieFiscal.vue'),
        beforeEnter: somenteAutenticado,
        meta: {
          layout: 'LayoutNavBar',
          titulo: 'De-para Séries Fiscal'
        }
      }
    ],
  },
  {
    path: '/lancamentos',
    name: 'rota-lancamento-contabil',
    component: () => import('../views/lancamentos/Lancamentos.vue'),
    beforeEnter: somenteAutenticado,
    meta: {
      layout: 'LayoutNavBar',
      titulo: 'Lançamento contábil'
    },
  },
  {
    path: '/escrituracao',
    name: 'rota-escrituracao',
    component: () => import('../views/escrituracao/Nota.vue'),
    beforeEnter: somenteAutenticado,
    meta: {
      layout: 'LayoutNavBar',
      titulo: 'Escrituração fiscal'
    },
  },
  {
    path: '/escrituracao/divergencias',
    name: 'rota-escrituracao-divergencias',
    component: () => import('../views/escrituracao/Divergencias.vue'),
    beforeEnter: somenteAutenticado,
    meta: {
      layout: 'LayoutNavBar',
      titulo: 'Conciliação de Notas'
    },
  },
  {
    path: '/monitor',
    name: 'rota-monitor-notas',
    // route level code-splitting
    // this generates a separate chunk (abou  t.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/monitor/Monitor.vue'),
    beforeEnter: somenteAutenticado,
    meta: {
      layout: 'LayoutNavBar',
      titulo: 'Monitor de notas'
    },
  },
  {
    path: '/logs',
    name: 'rota-logs',
    component: () => import('../views/logs/Logs.vue'),
    beforeEnter: somenteAutenticado,
    meta: {
      layout: 'LayoutNavBar',
      titulo: 'Logs'
    },
    children: [
      {
        path:'/logs/notas',
        name: 'rota-logs-notas',
        component: ()=> import('../views/logs/modulos/Notas.vue'),
        beforeEnter: somenteAutenticado,
        meta: {
          layout: 'LayoutNavBar',
          titulo: 'Log: Escrituração'
        }
      },
      {
        path:'/logs/lancamentos',
        name: 'rota-logs-lancamento',
        component: ()=> import('../views/logs/modulos/Lancamentos.vue'),
        beforeEnter: somenteAutenticado,
        meta: {
          layout: 'LayoutNavBar',
          titulo: 'Log: Lançamentos Contábeis'
        }
      },
      {
        path:'/logs/inventarios',
        name: 'rota-logs-inventario',
        component: ()=> import('../views/logs/modulos/Inventario.vue'),
        beforeEnter: somenteAutenticado,
        meta: {
          layout: 'LayoutNavBar',
          titulo: 'Log: Inventários'
        }
      }
    ]
  },
  {
    path: '*',
    name: 'rota-erro',
    component: () => import('../views/Error.vue'),
    beforeEnter: somenteAutenticado,
    meta: {
      layout: 'LayoutNavBar',
      titulo: 'Página Inexistente'
    },
  }
]

const router = new VueRouter({
  mode: 'history', //Remove # page
  routes
})

export default router
